import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import dayjs from 'dayjs';
import { find } from 'lodash';
import React, { FC, useState } from 'react';
import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import api, { getToken } from '../../api';
import { ApiHcp } from '../../api/hcp';
import { ApiHospital } from '../../api/hospital';
import { ApiOrder, ApiOrderProduct } from '../../api/order';
import { ApiProduct } from '../../api/product';
import { useProfile } from '../../lib/auth';
import { Button, Checkbox, Confirm, Dropdown, DropdownOption, Form, Grid, Message, Modal, PageHeader, Segment, toast } from '../../RbKit';
import Disclaimer from './Disclaimer';
import styles from './styles.module.scss';

const STATUS = [{
    text: 'New',
    value: 0,
}, {
    text: 'Signed',
    value: 1,
}, {
    text: 'Done',
    value: 2,
}, {
    text: 'Cancelled',
    value: 3,
}]

const checkIndication = (item: ApiOrderProduct): boolean => {
    if (
        item.indication1 === false ||
        item.indication3 === false ||
        item.indication2 !== undefined
    ) {
        return true;
    }

    return false;
}

interface OrderEditProps extends RouteComponentProps<{ id?: string }> {
}

const OrderEditView: FC<OrderEditProps> = ({ history, match }): JSX.Element => {
    const { id } = match.params;
    const [ hospitalMessage, setHospitalMessage ] = useState<boolean>(false);
    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ agree, setAgree ] = useState<number[]>([]);
    const [ availableModal, setAvailableModal ] = useState<boolean | string>(false);
    const [ errors, setErrors ] = useState<any>({});
    const [ step, setStep ] = useState<number>(1);
    const [ dStep, setDStep ] = useState<number>(1);
    const [ order, setOrder ] = useState<Partial<ApiOrder>>({});
    const [ products, setProducts ] = useState<ApiProduct[]>([]);
    const [ hospitals, setHospitals ] = useState<ApiHospital[]>([]);
    const [ limitReached, setLimitReached ] = useState<boolean | ApiProduct>(false);
    const [ limitHospitals, setLimitHospitals ] = useState<number[]>([]);
    const [ signature, setSignature ] = useState<string>();
    const [ hcp, setHcp ] = useState<ApiHcp>();
    const profile = useProfile();

    useEffect(() => {
        setHcp(profile?.hcp);
    }, [profile]);

    useEffect(() => {
        if (id) {
            api.getOrder(id).then(({ data }) => {
                setOrder(data);
                setStep(4);
            }).catch(() => {
                toast('Order not found', 'error');
                history.push('/orders');
            });
        }
    }, [id, history]);

    const handleInput = ({ name, value }: { [key: string]: any }): void => {
        setOrder({
            ...order,
            [name]: value,
        });
    }

    const handleAutoComplete = (query: string, callback: (options: DropdownOption[]) => void) => {
        if (query.length < 3) return;
        api.autocompleteProducts(query).then(({ data }) => {
            setProducts(data.data);

            callback(data.data.map((o) => ({
                text: `${o.name} - ${o.form}${o.isActive && o.isAvailable ? '' : ' (sample not available)'}`,
                value: o.id,
            })));
        });
    }

    const addProduct = (productId: number): void => {
        const product = find(products, { id: productId });
        if (!product) return;
        let cont: boolean = true;
        
        if (product.isActive) {
            if (product.dosages.length === 1) {
                const hasDosage = find(order.products || [], { productDosageId: product.dosages[0].id || 0 });
                if (hasDosage) {
                    cont = false;
                }
            }

            if (cont && product.isAvailable) {
                setOrder({
                    ...order,
                    products: [
                        ...(order.products || []), {
                            productId: product.id,
                            product,
                            productDosageId: product.dosages[0].id || 0,
                            productDosage: product.dosages[0],
                            amount: 1,
                        }
                    ],
                });

                if (product.hospitals) {
                    setLimitHospitals([
                        ...limitHospitals,
                        ...product.hospitals.map(o => o.id as number),
                    ]);
                }
            }
        } else if (product.availableMessage) {
            setAvailableModal(product.availableMessage);
        }
    }

    const handleProduct = (index: number, { name, value }: any): void => {
        if (!order || !order.products) return;
        const newProducts: any = [ ...order.products ];
        newProducts[index][name] = value;

        if (name === 'productDosageId') {
            const product = find(products, { id: newProducts[index].productId });
            if (product) {
                const dosage = find(product.dosages, { id: value });
                if (dosage) {
                    newProducts[index].productDosage = dosage;
                }
            }
        }

        setOrder({ ...order, products: newProducts });
    }

    const removeProduct = (index: number): void => {
        if (!order || !order.products) return;
        const newProducts: any = [ ...order.products.filter((o, i) => i !== index) ];
        setOrder({ ...order, products: newProducts });
        setLimitReached(false);
    }

    const handleHospitalAutoComplete = (query: string, callback: (options: DropdownOption[]) => void) => {
        api.listHospitals({ query }).then(({ data }) => {
            setHospitals(data.data);

            callback(data.data.map((o) => ({
                text: o.name,
                value: o.id,
            })));
        });
    }

    const handleHospital = (hospitalId: number): void => {
        const hospital = find(hospitals, { id: hospitalId });
        if (!hospital) return;

        if (limitHospitals.length > 0 && !limitHospitals.includes(hospitalId)) {
            setHospitalMessage(true);
        } else {
            setHospitalMessage(false);
        }

        setOrder({
            ...order,
            hospital: hospital.name,
            address: hospital.address,
            zipcode: hospital.zipcode,
            city: hospital.city,
        });
    }

    const handleAgree = (n: number, checked: boolean): void => {
        if (checked) {
            setAgree([ ...agree, n ]);
        } else {
            setAgree(agree.filter(o => o !== n));
        }
    }

    const handleHcpInput = ({ name, value }: any): void => {
        if (!hcp) return;
        setHcp({
            ...hcp,
            [name]: value,
        });
    }

    const save = (status?: number, type?: string): void => {
        if (isLoading) return;
        setErrors({});
        setIsLoading(true);
        if (!hcp && !order.hcp) return;

        api.putOrder({
            ...order,
            status: status || 0,
        }, (hcp || order.hcp) as ApiHcp, type).then(({ data }) => {
            setIsLoading(false);
            toast('Order saved succesfully');
            if (type === 'wet') {
                (window as any).open(`${process.env.REACT_APP_API_BASE_URL}orders/${data.id}/download?_token=${getToken()}`, '_blank').focus();
            }
            history.push('/orders');
        }).catch((err) => {
            setErrors(err.response.data.errors);
            setIsLoading(false);
            toast('Something went wrong', 'error')
        });
    }

    const checkProductLimits = () => {
        setLimitReached(false);
        setErrors({});
        setIsLoading(true);

        api.checkProductLimits(order).then(() => {
            setStep(3);
            setIsLoading(false);
        }).catch((err) => {
            const dosage = find(order.products, { productDosageId: err.response.data.errors.limit });
            if (dosage) {
                setLimitReached(dosage.product);
            }
            setIsLoading(false);
        });
    }

    if (!id && dStep <= 4) {
        return <Disclaimer onUpdate={(n: number) => setDStep(n)} />
    }

    return (<div className="container-small">
        <PageHeader
            title={order.id ? `#${order.number}` : 'New order'}
            breadcrumb={{
                '/orders': 'Orders',
                [order.id ? `/orders/${order.id}/edit` : '/orders/create']: order.id ? 'Overview' : 'New order'
            }}
        />
        <div className={styles.wizard}>
            <div>
                <div
                    className={`${styles.step} ${step >= 1 ? styles.active : ''} ${step >= 2 ? styles.done : ''}`}
                    onClick={() => setStep(!order.id && step > 1 ? 1 : step)}
                >
                    <div>1</div>
                    <span>Personal information</span>
                </div>
            </div>
            <div>
                <div
                    className={`${styles.step} ${step >= 2 ? styles.active : ''} ${step >= 3 ? styles.done : ''}`}
                    onClick={() => setStep(!order.id && step > 2 ? 2 : step)}
                >
                    <div>2</div>
                    <span>Products</span>
                </div>
            </div>
            <div>
                <div
                    className={`${styles.step} ${step >= 3 ? styles.active : ''} ${step >= 4 ? styles.done : ''}`}
                    onClick={() => setStep(!order.id && step > 2 ? 2 : step)}
                >
                    <div>3</div>
                    <span>Hospital</span>
                </div>
            </div>
            <div>
                <div className={`${styles.step} ${step >= 4 ? styles.active : ''}`}>
                    <div>4</div>
                    <span>Overview</span>
                </div>
            </div>
        </div>

        <Form>
            {hospitalMessage && (<Message
                error
                content="Some of the samples you've requested are not available for this hospital"
            />)}

            {step === 1 && (
                <Segment>
                    <h2 style={{ marginBottom: 5 }}>Personal information</h2>
                    <p>Please check your details and fill out any missing information</p>
                    <Form.Input
                        className={styles.noBorder}
                        label="Name"
                        required
                        value={profile?.hcp?.name || ''}
                    />
                    <Form.Input
                        className={styles.noBorder}
                        label="Registration number"
                        required
                        value={profile?.hcp?.nationalid || ''}
                    />
                    <Form.Input
                        className={styles.noBorder}
                        label="Date"
                        required
                        value={dayjs().format('DD-MM-YYYY')}
                    />
                    <Form.Input
                        error={errors.address}
                        label="Personal address"
                        name="address"
                        onChange={handleHcpInput}
                        required
                        value={hcp?.address || ''}
                    />
                    <Grid.Row>
                        <Grid.Column md={4}>
                            <Form.Input
                                error={errors.zipcode}
                                label="Personal zipcode"
                                name="zipcode"
                                onChange={handleHcpInput}
                                required
                                value={hcp?.zipcode || ''}
                            />
                        </Grid.Column>
                        <Grid.Column md={8}>
                            <Form.Input
                                error={errors.city}
                                label="Personal city"
                                name="city"
                                onChange={handleHcpInput}
                                required
                                value={hcp?.city || ''}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            primary
                            label="Continue"
                            disabled={!hcp?.address || !hcp?.zipcode || !hcp?.city}
                            onClick={() => setStep(2)}
                        />
                    </div>
                </Segment>
            )}
            {step === 2 && (
                <Segment>
                    <h2 style={{ marginBottom: 5 }}>Products</h2>
                    <p>Select sample products</p>

                    {limitReached !== false && (<Message
                        error
                        content={`You've reached your yearly sample limit for ${(limitReached as ApiProduct).name} ${(limitReached as ApiProduct).form}`}
                    />)}

                    <Form.Dropdown
                        label="Add samples (start by typing at least 3 characters)"
                        autoComplete={handleAutoComplete}
                        onChange={({ value }: any) => addProduct(value)}
                    />

                    {order.products?.map((op, index) => (
                        <div
                            key={index}
                            className={styles.product}
                        >
                            <div className={styles.productFields}>
                                <div className={styles.sample}>
                                    <span>{op.product.name} {op.product.form}</span>
                                    {op.product.link && op.product.link.includes('https:') && (<>
                                        <a
                                            href={op.product.link}
                                            rel="noopenerer noreferrer"
                                            target="_blank"
                                        >
                                            Details &amp; dosage calc.
                                        </a><br />
                                    </>)}
                                    {op.product.file && (
                                        <a
                                            href={op.product.file}
                                            rel="noopenerer noreferrer"
                                            target="_blank"
                                        >
                                            Details &amp; dosage calc. (PDF)
                                        </a>
                                    )}
                                </div>
                                <div className={styles.dosage}>
                                    <Form.Dropdown
                                        label="dosage"
                                        name="productDosageId"
                                        onChange={(data) => handleProduct(index, data)}
                                        options={op.product.dosages.map((o) => ({
                                            text: o.dosage,
                                            value: o.id,
                                        }))}
                                        value={op.productDosageId}
                                    />
                                </div>
                                <div className={styles.amount}>
                                    <Form.Dropdown
                                        label="Amount"
                                        name="amount"
                                        options={[1, 2, 3, 4, 5, 6, 7, 8].filter((o) => {
                                            return op.productDosage.maxAmount <= 0 || o <= op.productDosage.maxAmount;
                                        }).map((o) => ({
                                            text: `${o}`,
                                            value: o,
                                        }))}
                                        onChange={(data) => handleProduct(index, data)}
                                        value={op.amount}
                                    />
                                </div>
                                <Confirm
                                    trigger={<Button
                                        trigger
                                        icon={faTrashAlt}
                                    />}
                                    content="Are you sure you wish to remove this sample from your order?"
                                    onConfirm={() => removeProduct(index)}
                                />
                            </div>
                            <Segment className={`${styles.disclaimer} ${styles.productDisclaimer}`}>
                                <p>I intend to use this product in an indication that is not approved by the competent authorities: <span>*</span></p>
                                <div className={styles.buttons}>
                                    <div onClick={() => handleProduct(index, { name: 'indication1', value: true })}>
                                        <Button
                                            label="Yes"
                                            primary={op.indication1 === true}
                                        />
                                        <p>
                                            YES - however there is sufficient evidence available this is a valid and safe option for my patient and no other options are available
                                        </p>
                                    </div>
                                    <div onClick={() => handleProduct(index, { name: 'indication1', value: false })}>
                                        <Button
                                            label="No"
                                            primary={op.indication1 === false}
                                        />
                                        <p>
                                            NO - the indication is on-label but not reimbursed
                                        </p>
                                    </div>
                                </div>
                            </Segment>
                            {op.indication1 === true && (
                                <Segment className={`${styles.disclaimer} ${styles.productDisclaimer}`}>
                                    <p>Are the samples you are requesting for a treatment prolongation? <span>*</span></p>
                                    <div className={styles.buttons}>
                                        <div onClick={() => handleProduct(index, { name: 'indication3', value: true })}>
                                            <Button
                                                label="Yes"
                                                primary={op.indication3 === true}
                                            />
                                            <p>
                                                
                                            </p>
                                        </div>
                                        <div onClick={() => handleProduct(index, { name: 'indication3', value: false })}>
                                            <Button
                                                label="No"
                                                primary={op.indication3 === false}
                                            />
                                            <p>
                                                
                                            </p>
                                        </div>
                                    </div>
                                </Segment>
                            )}
                            {op.indication3 === true && (
                                <Segment className={`${styles.disclaimer} ${styles.productDisclaimer}`}>
                                    <p>I agree to be contacted in order to provide additional information (only for pharmacovigilance purposes)? This could allow quick identification of any new safety information.: <span>*</span></p>
                                    <div className={styles.buttons}>
                                        <div onClick={() => handleProduct(index, { name: 'indication2', value: true })}>
                                            <Button
                                                label="Yes"
                                                primary={op.indication2 === true}
                                            />
                                        </div>
                                        <div onClick={() => handleProduct(index, { name: 'indication2', value: false })}>
                                            <Button
                                                label="No"
                                                primary={op.indication2 === false}
                                            />
                                        </div>
                                    </div>
                                </Segment>
                            )}
                            <div className={styles.info}>
                            Healthcare professionals are asked to report any suspected adverse reactions and or any special situations related to the use of our product (such as pregnancy, breastfeeding, product complaint, misuse, overdose, abuse, lack of efficacy, interactions, medication error, occupational exposure). For reporting, you can click on this link: <a href="https://www.roche.be/fr/safety-reporting/adverse-event-reporting0.html" target="_blank">safety reporting page</a>
                            </div>
                            {op.product.information && (
                                <div className={styles.info}>
                                    {op.product.information}
                                </div>
                            )}
                        </div>
                    ))}

                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            primary
                            label="Continue"
                            disabled={!order.products ||
                                order.products.length <= 0 ||
                                order.products.filter(checkIndication).length < order.products.length
                            }
                            onClick={() => checkProductLimits()}
                        />
                    </div>
                </Segment>
            )}
            {step === 3 && (
                <Segment>
                    <h2 style={{ marginBottom: 5 }}>Hospital</h2>
                    <p>Select a Hospital where the samples shall be delivered to</p>
                    <Form.Dropdown
                        label="Hospital"
                        defaultOption={order.hospital}
                        autoComplete={handleHospitalAutoComplete}
                        onChange={({ value }: any) => handleHospital(value)}
                        defaultValue={order.hospital || ''}
                        required
                    />
                    <Form.Input
                        label="Additional delivery information"
                        name="comment"
                        onChange={handleInput}
                        value={order.comment || ''}
                    />
                    <Form.Input
                        error={errors.address}
                        label="Address"
                        name="address"
                        onChange={handleInput}
                        required
                        value={order.address || ''}
                    />
                    <Grid.Row>
                        <Grid.Column md={4}>
                            <Form.Input
                                error={errors.zipcode}
                                label="Zipcode"
                                name="zipcode"
                                onChange={handleInput}
                                required
                                value={order.zipcode || ''}
                            />
                        </Grid.Column>
                        <Grid.Column md={8}>
                            <Form.Input
                                error={errors.city}
                                label="City"
                                name="city"
                                onChange={handleInput}
                                required
                                value={order.city || ''}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            primary
                            label="Continue"
                            disabled={!order.hospital || !order.hospital || !order.address || !order.zipcode || !order.city}
                            onClick={() => setStep(4)}
                        />
                    </div>
                </Segment>
            )}
            {step === 4 && (
                <Segment isLoading={isLoading}>
                    <h2 style={{ marginBottom: order.id ? 20 : 5 }}>Overview</h2>
                    {!order.id && <p>Check your order and go to sign page to order the samples</p>}

                    {profile?.role === 'Administrator' && (
                        <Form.Dropdown
                            options={STATUS}
                            label="Current status"
                            value={order.status}
                            onChange={({ value }: any) => {
                                handleInput({ name: 'status', value });
                                save(value);
                            }}
                        />
                    )}

                    <div className={styles.overview}>
                        <div className={styles.col}>
                            <div className={styles.block}>
                                <h3>Personal information</h3>
                                <p>
                                    <b>Name</b>
                                    <span>{profile?.hcp?.name || order.hcp?.name}</span>
                                </p>
                                <p>
                                    <b>Reg.no.</b>
                                    <span>{profile?.hcp?.nationalid || order.hcp?.nationalid}</span>
                                </p>
                                <p>
                                    <b>Date</b>
                                    <span>{dayjs(order.createdAt).format('DD-MM-YYYY')}</span>
                                </p>
                            </div>
                            <div className={styles.block}>
                                <h3>Hospital</h3>
                                <p>
                                    <b>Name</b>
                                    <span>{order.hospital}</span>
                                </p>
                                <p>
                                    <b>Address</b>
                                    <span>{order.address}</span>
                                </p>
                                <p>
                                    <b>Zipcode</b>
                                    <span>{order.zipcode}</span>
                                </p>
                                <p>
                                    <b>City</b>
                                    <span>{order.city}</span>
                                </p>
                                {order.comment && (
                                    <p>
                                        <span style={{ fontStyle: 'italic' }}>
                                            {order.comment}
                                        </span>
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className={styles.col}>
                            <div className={styles.block}>
                                <h3>Samples</h3>
                                {order.products?.map((op, index) => (
                                    <div key={`op-${index}`} style={{ marginBottom: '1rem' }}>
                                        <p>
                                            <b>Product</b>
                                            <span>{op.product.name} {op.product.form}</span>
                                        </p>
                                        <p>
                                            <b>Dosage</b>
                                            <span>{op.productDosage.dosage}</span>
                                        </p>
                                        <p>
                                            <b>Amount</b>
                                            <span>{op.amount}</span>
                                        </p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    {!order.id && (<>
                        <div className={styles.checkbox}>
                            <Checkbox
                                checked={agree.includes(1)}
                                onChange={({ checked }: any) => handleAgree(1, checked)}
                                label="I confirm that the requested sample(s) serve(s) to remedy a medical need, urgent circumstances and/or a social need."
                            />
                        </div>
                        <div className={styles.checkbox}>
                            <Checkbox
                                checked={agree.includes(2)}
                                onChange={({ checked }: any) => handleAgree(2, checked)}
                                label="I confirm that I am recognised/experienced in the indication in which I intend to use the requested sample(s)."
                            />
                        </div>
                        <div className={styles.checkbox}>
                            <Checkbox
                                checked={agree.includes(3)}
                                onChange={({ checked }: any) => handleAgree(3, checked)}
                                label="I confirm that I have explored all treatment alternatives (included but not limited to commercially available medicinal products, inclusion in ongoing clinical trials and inclusion into Compassionate Use or Medical Need programs)."
                            />
                        </div>
                        <div className={styles.checkbox}>
                            <Checkbox
                                checked={agree.includes(4)}
                                onChange={({ checked }: any) => handleAgree(4, checked)}
                                label="I confirm that I have assessed the benefit-risk balance, based on my medical expertise and the currently existing scientific evidence, and that the benefits are outweiging the risks."
                            />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
                            <Button
                                onClick={() => setStep(3)}
                                label="Go back"
                            />
                            <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                                <Form.Dropdown
                                    label="Select signature"
                                    disabled={agree.length < 4 || hospitalMessage}
                                    options={[{
                                        text: 'Sign with ItsMe',
                                        value: 'itsme',
                                    }, {
                                        text: 'Sign with ID card',
                                        value: 'id',
                                    }]}
                                    onChange={({ value }) => setSignature(value)}
                                    value=""
                                />
                            </div>
                        </div>
                    </>)}
                </Segment>
            )}
        </Form>
        <Modal
            footer={(
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        label="Ok"
                        primary
                        onClick={() => setAvailableModal(false)}
                    />
                </div>
            )}
            header="Sample not available"
            onClose={() => setAvailableModal(false)}
            open={availableModal !== false}
            size="small"
        >
            {availableModal}
        </Modal>
        <Modal
            footer={(
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        label="Cancel"
                        onClick={() => setSignature(undefined)}
                    />
                    <Button
                        disabled={isLoading}
                        label="Sign"
                        primary
                        onClick={() => save(0, signature)}
                    />
                </div>
            )}
            header="Attention"
            onClose={() => setSignature(undefined)}
            open={signature !== undefined}
            size="small"
        >
            {signature === 'wet' && (<>
                <p>
                    A signature is needed to validate and finalize this Sample Request.  
                    You have opted to sign using a wet signature, please:
                </p>
                <ol>
                    <li>download the request overview</li>
                    <li>sign the request overview</li>
                    <li>scan the signed request overview (in PDF format)</li>
                    <li>upload the scanned document (PDF) into the request</li>
                </ol>
                <p>
                    Please be aware, that this request will not be processed before a signature has been obtained. More information can be found in the Help section
                </p>
            </>)}
            {signature === 'itsme' && (<>
                <p>
                    A signature is needed to validate and finalize this Sample Request.  
                    You have opted to sign using ItsMe:
                </p>
                <ol>
                    <li>A DocuSign email will be sent to you</li>
                    <li>Open the email and click on the sign button</li>
                    <li>Follow instructions (enter phone number for ItsMe, etc)</li>
                    <li>Open ItsMe on your smartphone and sign using your PIN code</li>
                </ol>
                <p>
                    Please be aware, that this request will not be processed before a signature has been obtained. More information can be found in the Help section.
                </p>
            </>)}
            {signature === 'id' && (<>
                <p>
                    A signature is needed to validate and finalize this Sample Request.  
                    You have opted to sign using your ID Card:
                </p>
                <ol>
                    <li>A DocuSign email will be sent to you</li>
                    <li>Ensure that a card reader is installed, insert your ID Card in the reader</li>
                    <li>Open the email and click on the sign button</li>
                    <li>Follow instructions (install browser extensions if needed)</li>
                    <li>Sign using your PIN code</li>
                </ol>
                <p>
                    Please be aware, that this request will not be processed before a signature has been obtained. More information can be found in the Help section
                </p>
            </>)}
        </Modal>
    </div>);
}

export default OrderEditView;
